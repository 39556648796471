.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: $sidebar-color;
  height: 100%;
  width: 280px;
  min-width: 280px;
  .btn-fixed-bottom {
    position: fixed;
    right: calc(100% - 280px);
    width: 280px;
  }
  .sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 280px;
    height: 52px;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    z-index: 3;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sidebar-body {
  position: absolute;
  padding: 3rem 0;
  top: 0px;
  bottom: 52px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
  .nav-link {
    padding: 0.5rem 1.5rem;
    &.active {
      position: relative;
      background-color: #fff;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 3px;
        top: 0;
        height: 100%;
        background-color: $green;
      }
    }
  }
}
.sidebar-heading {
  font-size: 16px;
  color: $darkest;
  font-weight: $bold;
  padding: 0.65rem 1.5rem;
  margin: 0;
  &.hoverable {
    cursor: pointer;
    &:hover {
      position: relative;
      background-color: #fff;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 3px;
        top: 0;
        height: 100%;
        background-color: $green;
      }
    }
  }

  &.selected {
    position: relative;
    background-color: #fff;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 3px;
      top: 0;
      height: 100%;
      background-color: $green;
    }
  }
}
.content-with-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 280px;
  box-sizing: border-box;
  outline: none !important;
  overflow: hidden;
  .btn-fixed-bottom {
    position: fixed;
    left: 280px;
    right: 1px;
    width: calc(100% - 280px);
  }
  .content-with-sidebar-body {
    position: absolute;
    padding: 3rem 0;
    top: 0px;
    bottom: 52px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
  }
  .content-with-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 52px;
    border-top: 1px solid $border-color;
    z-index: 3;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-sub-heading {
  color: $label-color;
  font-size: 12px;
  font-weight: $bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.65rem 1.5rem;
  .btn-link {
    color: $label-color;
    font-size: 12px;
    padding-right: 0px;
    &:hover {
      color: $green;
    }
  }
}

.back-link {
  font-size: 12px;
  padding-left: 0px;
}

.sidebar {
  form {
    .label {
      color: $darkest;
    }
  }
}

.subtotal-nav {
  color: $darkest;
  margin-bottom: 1rem;
  .label {
    color: $darkest;
    font-size: 14px;
  }
  li {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 36px;
  }
}

.order-info-nav {
  li {
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    .label {
      width: 100px;
    }
    .info {
      text-align: right;
      white-space: pre-wrap;
      width: 120px;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }
  }
}
