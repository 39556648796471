.table {
  thead {
    th {
      font-size: 12px;
      border-top: 0px;
    }
  }
  tbody {
    tr {
      &:last-child {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  .olod-dots {
    i {
      font-size:0.1px !important;
    }
  }
  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    background-color: #fafbfc;
    cursor: pointer;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  max-height: calc(100vh - 188px);
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-align-middle {
  td {
    vertical-align: middle;
  }
}

.post-table {
  td {
    min-width: 120px;
  }
  .desc {
    min-width: 300px;
    div {
      @include multiLineEllipsis(
        $lineHeight: 1.2em,
        $lineCount: 3,
        $bgColor: white
      );
      // overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      // border-top: 0;
    }
  }
}




