.panel {
  margin-bottom: 1.5rem;

  label {
    font-weight: $semi-bold;
    margin-bottom: 4px;
  }

  .img {
    width: 160px;
    height: 90px;
    object-fit: cover;
    &.logo {
      object-fit: contain;
    }
    &.pavicon {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}
