.orders-summary {
  border: 1px solid $border-color;
  background-color: $sidebar-color;
  width: 100%;
  max-width: 1200px;
  min-width: 768px;
  margin: auto;
}
.orders-summary-body {
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    color: $darkest;
    font-weight: $bold;
    margin: 0.5rem 0;
    font-size: 24px;
  }
  .title {
    font-size: 12px;
  }
  .sub {
    font-size: 12px;
    color: $gray-300;
  }
}

.order-detail {
  width: 350px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 5px;

  .summary {
    padding: 1.5rem 0;
    li {
      display: flex;
      justify-content: space-between;
      padding: 4px 0;
      font-size: 16px;
      .label {
        font-size: 16px;
        font-weight: $regular;
        color: $darkest;
      }
      &.total {
        padding: 1rem 0;
        font-weight: $bold;
        font-size: 20px;
        .label {
          font-weight: $bold;
          font-size: 20px;
        }
      }
    }
  }
}
.order-detail-body {
  padding: 2rem 1.5rem;
  h1 {
    font-size: 20px;
    font-weight: $bold;
    color: $darkest;
    text-align: center;
  }
}
.order-item {
  border-bottom: 1px solid $border-color;
  padding: 1.5rem 0;

  .header {
    font-weight: $bold;
    font-size: 16px;
    .num {
      color: $gray-300;
      text-align: center;
      margin-right: 0.5rem;
    }
    .title {
      color: $darkest;
    }
  }

  .body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .nav {
      width: 200px;
      border-left: 1px solid $border-color;
      padding-left: 1rem;
      margin-left: 5px;
      color: $gray-300;
      li {
        padding: 2px 0;
      }
    }
    .subtotal {
      font-size: 16px;
      font-weight: $bold;
      color: $darkest;
    }
  }
}

.order-coupon {
  padding: 1.5rem 0;
  border-bottom: 1px solid $border-color;
  h5 {
    font-size: 16px;
    font-weight: $bold;
    color: $darkest;
  }
  .applied {
    border: 1px solid $border-color;
    padding: 0.5rem;
    h6 {
      color: $darkest;
      font-weight: $semi-bold;
      font-size: 14px;
    }
  }
}
