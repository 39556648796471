.banner-image-upload {
  width: 150px;
  height: 100px;
  cursor: pointer;
  position: relative;
  input[type="file"] {
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .preview {
    width: 150px;
    height: 100px;
    border-radius: 2px;
    box-shadow: $small-shadow;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .label {
    position: absolute;
    color: $green;
    width: 100% !important;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    text-align: center;
  }
}
