.orders-status-tabs {
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
}

.status-tab.btn.btn-secondary {
  width: calc(33% - 0.5rem);
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  height: auto;
  color: $dark;
  outline: none !important;

  .n {
    font-size: 10px;
  }

  &.active {
    color: $green;
    background-color: #fff;
    border-color: $green;
  }
}

.search-order {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;

  .form-control {
    margin-top: 0.7rem;
    width: calc(50% - 0.5rem);
    box-shadow: $small-shadow;
    border-color: transparent;
  }
}

.nav-order {
  border-left: 4px solid transparent;
  padding: 1rem 1rem 1rem 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $border-color;
  cursor: pointer;

  .oinfo {
    width: calc(100% - 130px);
  }

  .oaction {
    width: 120px;

    .ostatus {
      font-size: 12px;
      text-align: center;
    }
  }

  .otag {
    font-size: 12px;
    color: #fff;
    background-color: $dark;
    width: 116px;
    padding: 0.4rem 0;
    border-radius: 2em;
    text-align: center;
    margin-left: auto;
    font-weight: $semi-bold;

    &.warning {
      background-color: $warning;
    }

    &.danger {
      background-color: $danger;
    }

    &.success {
      background-color: $success;
    }

    &.text {
      background-color: transparent;
      color: #152043;
      padding: 0;
    }
  }

  .odelay {
    text-align: center;
    line-height: 1;
    padding-top: 0.25rem;
    font-weight: $semi-bold;
  }

  .oname {
    font-size: 16px;
    color: $dark;
    font-weight: $bold;
  }

  .odesc {
    display: flex;
    font-size: 12px;
    color: $gray-200;
  }

  .otype {
    font-weight: $bold;
    color: $blue;
    margin-right: 4px;
    font-size: 12px;
    &.pickup {
      color: $blue;
    }
    &.delivery {
      color: $green;
    }
    &.curbside {
      color: $orange;
    }
  }

  .oid {
    margin-right: 4px;
  }

  .oqty {
    &::before {
      content: "/";
      display: inline-block;
    }
  }

  &.active {
    border-left-color: $green;
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
  }
}

.od-order {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.od-header {
  display: flex;
  justify-content: space-between;
}

.od-h-item {
  border: 2px solid $border-color;
  border-radius: 2px;
  padding: 1rem 0;
  text-align: center;
  width: 200px;

  .od-h-i-t {
    display: block;
    font-size: 10px;
    color: $gray-200;
  }

  .od-h-i-d {
    display: block;
    font-size: 14px;
    font-weight: $bold;
    color: $dark;
    text-transform: capitalize;
  }

  &.request {
    border-color: $yellow;
    background-color: $yellow;
    .od-h-i-t {
      color: $dark;
    }
  }

  &.confirmed {
    border-color: $orange;
  }

  &.ready {
    border-color: $green;
  }

  &.done {
    border-color: $dark;
  }
}

.od-status-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 4rem;

  &::before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    background-color: $border-color;
  }

  &::after {
    content: "";
    display: block;
    width: 0%;
    position: absolute;
    left: 0;
    height: 4px;
    background-color: $green;
  }

  &.active0 {
    &::after {
      width: 0%;
    }
  }

  &.active1 {
    &::after {
      width: 25%;
    }
  }

  &.active2 {
    &::after {
      width: 75%;
    }
  }

  &.active3 {
    &::after {
      width: 100%;
    }
  }
}

.od-status-label {
  position: absolute;
  left: calc(25% + 6.5px);
  right: calc(50% - 60px);
  text-align: right;

  span {
    position: absolute;
    right: -1px;
    background-color: $warning;
    border-radius: 2em;
    width: 120px;
    height: 30px;
    line-height: 30px;
    top: -15px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-weight: $semi-bold;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    height: 4px;
    background-color: $warning;
  }

  &.danger {
    &::before {
      background-color: $danger;
    }

    span {
      background-color: $danger;
    }
  }
}

.od-status-dot {
  position: absolute;
  width: 10px;
  z-index: 1;

  span {
    display: block;
    margin-top: 1rem;
    font-size: 14px;
    font-weight: $semi-bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  &::after {
    content: "";
    position: absolute;
    left: -0px;
    top: -6.5px;
    width: 16px;
    height: 16px;
    border: 4px solid #fff;
    border-radius: 50%;
    background-color: $border-color;
  }

  &:nth-child(1) {
    left: -6.5px;

    span {
      left: 6px;
      transform: translateX(0);
    }
  }

  &:nth-child(2) {
    left: 25%;
  }

  &:nth-child(3) {
    left: 75%;
  }

  &:nth-child(4) {
    left: 100%;

    span {
      left: auto;
      right: 0;
      transform: translateX(0);
    }

    &::after {
      left: 0px;
    }
  }

  &.active {
    span {
      color: $green;
    }

    &::after {
      background-color: $green;
    }
  }
}

.od-items {
  padding-top: 2rem;
}

.od-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .customer-note {
    color: $green;
  }
}

.odi-header {
  font-weight: $semi-bold;
  width: 60px;
}

.odi-qty {
  padding: 2px 4px;
  &.warning {
    background-color: $yellow;
    border-radius: 2px;
  }
}

.odi-body {
  width: calc(100% - 60px - 120px);

  .name {
    font-weight: $semi-bold;
  }

  .desc {
    color: $gray-200;
  }

  .location-note {
    color: $green;
    font-weight: $semi-bold;
  }
}

.odi-footer {
  width: 120px;
  text-align: right;

  .price {
    color: $gray-200;
  }

  .opp-original {
    text-decoration: line-through;
    margin-right: 6px;
  }
}

.od-summary {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
}

.ods-header {
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .odsh-qty {
    font-weight: $bold;
  }

  .odsh-curbside {
    span {
      font-weight: $semi-bold;
    }
  }
  .color {
    .White {
      &::before {
        font-weight: 300;
        color: #eaeaea;
        content: "\f0c8";
      }
    }
    .Black {
      color: #000;
    }
    .Silver {
      color: #d9d9d9;
    }
    .Blue {
      color: #001ec3;
    }
    .Red {
      color: #e51616;
    }
    .Brown {
      color: #724212;
    }
    .Green {
      color: #499f00;
    }
    .Other {
      &::before {
        font-weight: 300;
        color: #eaeaea;
        content: "\f2fd";
      }
    }
  }

  .odsh-actions {
    display: flex;

    .btn {
      + .btn {
        margin-left: 0.5rem;
      }
    }
  }
}

.ods-footer {
  width: 200px;

  table {
    width: 100%;

    td {
      &:last-child {
        text-align: right;
      }
    }

    tr {
      &:last-child {
        td {
          font-weight: $bold;
        }
      }
    }
  }
}

.delay-time-selects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  .input-time {
    // width      : 400px;
    margin: auto;
    display: flex;
    align-items: center;

    .form-control {
      width: 150px;
      font-size: 50px;
      text-align: center;
      border: 0px;
      border-bottom: 1px solid $dark;
      border-radius: 0px;
    }

    label {
      font-size: 30px;
      margin-left: 1rem;
      color: $dark;
      margin: auto;
    }
  }
}

.delay-item-select,
.reason-item-select {
  width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $border-color;
  margin: 0.5rem;
  border-radius: 2px;
  cursor: pointer;
  color: $dark;

  &.selected {
    background-color: $dark;
    color: #fff;
    border-color: $dark;
  }

  &:hover {
    background-color: $border-color;
    border-color: $border-color;
    color: $dark;
  }
}

.delay-item-select {
  flex-direction: column;
}

.reason-item-select {
  width: 100%;
  height: 60px;
}

@include media-breakpoint-up(md) {
  .confirm-order-modal.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

.request-alert {
  width: 350px;
  text-align: center;
  margin: auto;
  padding: 6px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: $semi-bold;
  margin-top: 1rem;
  &.warning {
    background-color: #ffd600;
    color: $dark;
  }
  &.success {
    background-color: rgba($green, 0.05);
    color: $green;
  }
}
