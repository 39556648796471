.alert {
  padding: 1rem 1.5rem;
  border-top-width: 3px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  color: $darkest;
  border-radius: 2px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.alert-danger {
  background-color: rgba($red, 0.1);
  border-color: $red;
}
.alert-success {
  background-color: rgba($green, 0.1);
  border-color: $green;
}
.alert-warning {
  background-color: rgba($warning, 0.1);
  border-color: $warning;
}
.alert-info {
  background-color: rgba($blue, 0.1);
  border-color: $blue;
}
.alert-dismissible .close {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: #ddd;
  font-weight: $regular;
  font-size: 22px;
  &:hover {
    color: $gray-300;
  }
}

.alert-gray {
  background-color: $gray;
  color: #fff;
}
.alert-gray-2 {
  background-color: rgba($gray, 0.1);
  color: $gray;
}
.alert-green {
  background-color: rgba($green, 0.1);
  color: $green;
}
.alert-red {
  background-color: rgba($red, 0.1);
  color: $red;
}

/**
* react confirm alert
*/
.react-confirm-alert-body > h1 {
  font-size: 20px;
  color: #222;
}
