.Toastify__toast {
  border-radius: 6px;
}
.Toastify__toast--success {
  background: $success;
}
.Toastify__toast--warning {
  background: $orange;
}
.Toastify__progress-bar {
  background-color: rgba(0, 0, 0, 0.05);
}
