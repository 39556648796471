.auth-layout {
  background-color: #fafbfc;
  min-height      : 100vh;

  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.auth-main {
  min-height: calc(100vh - 100px);
}

.auth-footer {
  height: 100px;

  .nav {
    justify-content: center;
    align-items    : center;

    li {
      padding: 1rem;

      a {
        font-weight: $regular;
        color      : $label-color;
      }
    }
  }
}

.auth-brand-selection {
  .brand-card:hover {
    transform : translateY(-3px);
    transition: all 0.1s ease-in-out;
  }

  p {
    cursor: pointer;
  }
}

.resend-code {
  color : $green;
  cursor: pointer;
}

.code-input-wrap {
  position: relative;
  display : flex;
  width   : 280px;
  margin  : auto;

  .input {
    position        : absolute;
    border          : none;
    font-size       : 30px;
    text-align      : center;
    background-color: transparent;
    outline         : none !important;
  }

  .display {
    border-bottom  : 1px solid rgba(0, 0, 0, 0.2);
    width          : 60px;
    height         : 58px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-size      : 40px;
    position       : relative;
    color          : var(--dark);
    margin         : auto 5px;
  }

  .shadows {
    position     : absolute;
    left         : 0;
    top          : 0;
    bottom       : 0;
    right        : 0;
    border-bottom: 1px solid var(--primary);
  }
}