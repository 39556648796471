.main-card {
  margin-top   : 21px;
  min-height   : calc(100vh - 78px);
  box-shadow   : 0 5px 30px rgba(0, 0, 0, 0.1);
  border-width : 0 !important;
  border-radius: 12px !important;

  .card-header {
    border-top-left-radius : 12px;
    border-top-right-radius: 12px;
    background-color: #fff;
    &.dark {
      background-color: #8898aa;
      color           : #fff;

      .btn-link {
        color: #fff;
      }
    }


  }
}

.table thead th {
  border-bottom   : 1px solid #eaedf0;
  background-color: #f6f9fc;
  border-top      : 0px;
  color           : #8898aa;
}

.form-control {
  font-size    : 12px;
  border-radius: 2px;
  height       : 36px;
}

.navbar-light .navbar-nav .nav-link {
  color: #222222;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #03c9a9;
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    border-left: 2px solid #03c9a9;
  }
}

.auth-layout {
  height    : 100vh;
  width     : 100vw;
  overflow-x: hidden;
  overflow-y: auto;

  .logo {
    width     : 300px;
    margin    : auto;
    display   : block;
    margin-top: 39px;
  }
}

.full-page {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.auth-card {
  width        : 100%;
  max-width    : 500px;
  margin       : 2rem auto;
  padding      : 1.25rem;
  border-radius: 12px;
  border-width : 0px;
  box-shadow   : 0 5px 30px rgba(0, 0, 0, 0.1);
}

.table-hover tbody tr:hover {
  background-color: #f6f9fc;
  cursor          : pointer;
  
}

.fillter-input {
  font-size    : 12px;
  border-radius: 2px;
  height       : 36px;
  width:100%;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  color:#8898aa !important;
  border: 1px solid #eaeaea !important;
  &:focus {
    border: 1px solid #03c9a9 !important;
  }
}
.fillter-input-content {
  font-size    : 12px;
  border-radius: 2px !important;
  height       : 36px !important;
  color:#8898aa !important;
  width:100%;
  padding: 0 !important;
  border: 1px solid #eaeaea !important;
  &:focus {
    border: 1px solid #03c9a9 !important;
  }

}

.svg-uploads-left {
  margin-left:19px !important;
  overflow: hidden;
  vertical-align:right;
  width:12px;
  height:12px;
}
.svg-uploads-right {
  margin-left:10px !important;
  overflow: hidden;
  vertical-align:right;
  width:12px;
  height:12px;
}

.BRANDS__control{
  font-size: 12px !important;
  border-radius: 2px !important;
  height: 36px !important;
  width:100%  !important;
  min-height: 36px !important;
  color: #8898aa !important;
  border: 1px solid #eaeaea !important;
  &:focus {
    border: 1px solid #03c9a9 !important;
  }
  
    .BRANDS__indicator-separator{
      /* opacity: revert; */
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      background-color: hsl(0,0%,80%);
      margin-bottom: 8px;
      margin-top: 8px;
      width: 0px; 
      box-sizing: border-box;
    }
    .BRANDS__single-value{
      color: #8898aa;
      margin-left: 2px;
      margin-right: 2px;
      max-width: calc(100% - 8px);
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      box-sizing: border-box;
    }
  
}

.STATUS__control{
  font-size: 12px;
  border-radius: 2px !important;
  height: 36px !important;
  min-height: 36px !important;
  width:100%  !important;
  border: 1px solid #eaeaea !important;
  &:focus {
    border: 1px solid #03c9a9 !important;
  }
  .STATUS__indicator-separator{
    /* opacity: revert; */
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0,0%,80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 0px; 
    box-sizing: border-box;
  }
  .STATUS__single-value{
    color: #8898aa;
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }

}

.text-center {
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  text-align: center !important;
}
.text-right {
height: 100%;
font-size: 18px;
font-weight: 600;
text-align: center !important;
}

input::placeholder{
  color: #8898aa !important;
}

.btn-primary {
  color: #fff;
  background-color: #03c9a9 !important;
  border-color: #03c9a9 !important;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  position: relative;
  left: 0;
  top: 2px;
  padding-left: 0 !important;
}

@import "variables";

@import "node_modules/bootstrap/scss/bootstrap";

@import "mixins";
@import "text";
@import "card";
@import "toast";
@import "alert";
@import "timePicker";
@import "panel";
@import "form";
@import "dropzone";
@import "table";
@import "modal";
@import "navbar";
@import "button";

@import "sidebar";
@import "ordersToday";
@import "orders";
@import "auth";
@import "select";
@import "upload";
@import "common";
@import "dialog";