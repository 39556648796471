.MuiDialogContent-root {
    margin-left: 15px;
    margin-right: 15px;
    flex: 1 1 auto;
    margin: 12p;
    padding: 8px 24px;
    -webkit-overflow-scrolling: touch;
  }
  
  .MuiTypography-h6 {
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
  
  .MuiDialogTitle-root {
    font-size: 16px;
    flex: 0 0 auto;
    margin: 0;
    padding: 10px 24px;

  }
  
  .MuiDialog-paperScrollPaper {
    display: flex;
    max-height: calc(100% - 400px) !important;
    flex-direction: column;
  }