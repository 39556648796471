.modal-header {
  border-bottom: 0px;
  background-color: $sidebar-color;

  .modal-title {
    font-size: 16px;
    font-weight: $bold;
    color: $purple;
  }
}

.modal-footer {
  border-top: 0px;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.5rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.5rem;
}

.sort-modal {
  &.modal-dialog {
    max-width: 350px;
  }

  .modal-content {
    border: 0px;
  }

  .modal-body {
    min-height: 30vh;
    max-height: 50vh;
    overflow: auto;
  }

  .modal-footer {
    position: relative;

    .btn {
      width: calc(50% - 12px);
      box-shadow: $small-shadow;
    }

    &::before {
      position: absolute;
      content: "";
      height: 30px;
      top: -30px;
      left: 0;
      right: 0;
      background-image: linear-gradient(to top, #fff, transparent);
    }
  }
}

.sort-nav {
  flex-direction: column;

  li {
    padding: 0.35rem 0;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .num {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $border-color;
      color: $gray-500;
      line-height: 18px;
      text-align: center;
      margin-right: 0.5rem;
      font-size: 8px;
      font-weight: $bold;
    }

    .name {
      font-weight: $bold;
      width: calc(100% - 80px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .btn {
      width: 40px;
      padding: 0;
    }
  }
}

.modal-w-sidebar {
  &.modal-dialog {
    max-width: 547px;
  }

  .modal-content {
    border: 0px;
  }

  .modal-body {
    min-height: 400px;
    max-height: 80vh;
    overflow: auto;
    position: relative;
  }

  .modal-sidebar {
    position: absolute;
    width: 200px;
    background-color: $sidebar-color;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;

    li {
      padding: 0.5rem 1rem;
      color: $purple;
      font-weight: $semi-bold;
      cursor: pointer;

      &:hover {
        background-color: #fff;
      }

      &.selected {
        background-color: #fff;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: $green;
        }
      }
    }
  }

  .modal-sidebar-body {
    position: absolute;
    top: 0;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
  }

  .modal-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .modal-content-w-sidebar {
    position: absolute;
    width: calc(100% - 200px);
    top: 0;
    left: 200px;
    bottom: 0;
    right: 0;
  }

  .modal-content-w-sidebar-body {
    position: absolute;
    top: 0;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    padding: 1rem 2rem;
  }

  .modal-content-w-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem 2rem;
  }
}

.modifier-template-modal {
  max-width: 600px;

  .modal-content {
    .top-left {
      min-width: 200px;
      width: 200px;
      background-color: $sidebar-color;
      min-height: 350px;
      max-height: 600px;
      overflow-y: scroll;

      .item {
        cursor: pointer;
        padding: 12px 16px;

        &:hover {
          padding-left: 13px;
          background-color: white;
          border-left: 3px solid $green;
        }
      }

      .item.selected {
        padding-left: 13px;
        background-color: white;
        border-left: 3px solid $green;
      }
    }

    .top-right {
      overflow-y: scroll;
      max-height: 600px;
      overflow-y: scroll;

      .list-group-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .bottom {
      .bottom-left {
        min-width: 200px;
        width: 200px;
        background-color: $sidebar-color;
      }

      .bottom-right {
      }
    }
  }
}
