.dashboard-navbars {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.navbar-main {
  background-color: #fff;
  &.navbar {
    padding: 0;
  }
  .fa-heart {
    font-size: 20px;
    margin: 0 0.75rem;
  }
  .navbar-brand {
    margin-left: 1.5rem;
    margin-right: 0;
    line-height: 60px;
    padding: 0;
    .icon {
      width: 18px;
      margin: 0 20px;
    }
  }
  .brand-location-nav {
    position: relative;
    overflow-y: hidden;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .brand-name {
    font-weight: $bold;
    color: $darkest;
    font-size: 22px;
    line-height: 26px;
    transform: translate(0px, 10px);
    white-space: nowrap;
    animation: showBrand 0.5s ease;
  }

  .location-name {
    transform: translate(0px, 100px);
    white-space: nowrap;
  }

  .with-location {
    &.brand-name {
      font-weight: $regular;
      color: $darkest;
      font-size: 8px;
      line-height: 10px;
      transform: translate(0px, 0px);
      animation: fadeIn 0.5s ease;
    }
    &.location-name {
      font-weight: $bold;
      color: $darkest;
      font-size: 22px;
      line-height: 26px;
      transform: translate(0px, -4px);
      animation: showLocation 0.5s ease;
    }
  }

  @keyframes showBrand {
    from {
      opacity: 0;
      transform: translate(0px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0px, 10px);
    }
  }

  @keyframes showLocation {
    from {
      transform: translate(0px, 10px);
    }
    to {
      transform: translate(0px, -4px);
    }
  }

  .nav-profile {
    background-color: $green;
    color: #fff !important;
    display: flex;
    align-items: center;
    height: 60px;
  }
  .nav-link {
    height: 60px;
    padding: 0 1.5rem !important;
    color: #222;
    font-weight: $semi-bold;
    position: relative;
    display: flex;
    align-items: center;
    .icon {
      width: 18px;
      margin-right: 10px;
    }
    &.active {
      &::before {
        content: "";
        height: 3px;
        width: 44px;
        background-color: $green;
        border-radius: 1.5px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }
}

.navbar-sub {
  background-color: #fff;
  border-top: 1px solid $border-color;
  &.navbar {
    padding: 0 1rem;
  }
  .nav-link {
    line-height: 40px;
    padding: 0 1rem !important;
    color: $gray-500;
    .icon {
      width: 18px;
      margin-right: 10px;
    }
    &.active {
      color: $purple;
      font-weight: $semi-bold;
      // pointer-events: none;
    }
  }
}

.nav-logo {
  width: 300px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.table {
  .text-success,
  .text-info,
  .text-danger {
    font-weight: $semi-bold;
  }
}
