.text-darkest {
  color: $darkest !important;
}

.text-purple {
  color: $purple;
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

.text-muted {
  color: $label-color !important;
}

.text-gray-300 {
  color: $gray-300 !important;
}

.text-secondary {
  color: $gray-500 !important;
}

.text-pickup {
  color: $pickup !important;
  font-weight: 700 !important;
}

.text-delivery {
  color: $delivery !important;
  font-weight: 700 !important;
}

.text-curbside {
  color: $curbside !important;
  font-weight: 700 !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-22 {
  font-size: 22px;
}

.lh-44 {
  line-height: 44px !important;
}

::selection {
  background: rgba($green, 0.3) !important;
}

::-moz-selection {
  background: rgba($green, 0.3) !important;
}

.semi-bold {
  font-weight: $semi-bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $bold;
}

.topic-td {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.td-md {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status-red {
  font-weight: $semi-bold;
  color: $red;
}
.status-gray {
  font-weight: $semi-bold;
  color: $gray;
}
.status-blue {
  font-weight: $semi-bold;
  color: $blue;
}
.status-green {
  font-weight: $semi-bold;
  color: $green;
}
.status-orange {
  font-weight: $semi-bold;
  color: $orange;
}
