body {
  font-size: 14px;
}

.dashboard-layout {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.main-content {
  position: relative;
  height: 100%;
  overflow: auto;
}

.content-no-sidebar {
  position: absolute;
  padding: 3rem 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.ifcon {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.ifcon-2x {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.icon-angle-right {
  background-color: $darkest;
  -webkit-mask: url(../icon/angle-right-light.svg) no-repeat center;
  mask: url(../icon/angle-right-light.svg) no-repeat center;
}

.icon-arrow-left {
  background-color: $green;
  -webkit-mask: url(../icon/long-arrow-alt-left-regular.svg) no-repeat center;
  mask: url(../icon/long-arrow-alt-left-regular.svg) no-repeat center;
}

.icon-sort {
  background-color: $label-color;
  -webkit-mask: url(../icon/sort-alt-regular.svg) no-repeat center;
  mask: url(../icon/sort-alt-regular.svg) no-repeat center;
}

.icon-pen-solid {
  background-color: $green;
  -webkit-mask: url(../icon/pen-solid.svg) no-repeat center;
  mask: url(../icon/pen-solid.svg) no-repeat center;
}

.menu-nav {
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkest;
    font-weight: $semi-bold;

    &:hover {
      background-color: #fff;
      position: relative;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        height: 100%;
        width: 3px;
        background-color: $green;
      }
    }
  }
}

.page-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  color: $gray-500;
}

.label {
  color: $label-color;
  font-weight: $bold;
  font-size: 12px;
  line-height: 21px;
}

.body-content {
  width: 550px;
  margin: auto;
}

.label-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;

  .label {
    width: 100px;
    padding: 6px 0;
    text-transform: uppercase;
  }

  .error {
    color: $dark;
    width: 100%;
    padding: 6px 1rem;
    font-size: 12px;
    background-color: rgba($red, 0.1);
    border-top: 2px solid $red;
    margin-top: 6px;
  }

  .lcontent {
    width: calc(100% - 120px);
    margin-left: 20px;

    h6 {
      margin: 0;
      font-weight: $regular;
      font-size: 14px;
      padding: 6px 0;
      line-height: 21px;
    }

    h4,
    h5 {
      margin: 0;
      font-weight: $bold;
      padding: 6px 0;
      line-height: 21px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    p {
      padding: 6px 0;
    }

    &.p {
      padding: 6px 0;
    }

    .location-img {
      width: 128px;
      height: 128px;
      object-fit: cover;
    }

    .img {
      width: 180px;
      height: auto;
    }
  }
}

.image-upload-area {
  width: 150px;
  height: 100px;
  background-color: #eaeaea;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-nav {
  li {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid $border-color;
    font-weight: $semi-bold;
  }
}

.select-options {
  font-size: 12px;
  text-align: center;
  background-color: #eaeaea;
  padding: 0.725rem 1rem;
  border-radius: 2px;

  &.edit {
    background-color: #fff;
    border: 1px solid $border-color;
  }

  .basic-single {
    width: 56px;
    margin: auto;
  }
}

.divider {
  height: 0;
  display: block;
  width: 100%;
  border-top: 1px solid $border-color;
  margin: 1rem 0;
}

.w-150 {
  width: 150px;
}

.w-160 {
  width: 160px;
}

.w-400 {
  width: 400px;
}

.mw-400 {
  max-width: 400px;
}

.w-500 {
  width: 500px;
}

.mw-500 {
  max-width: 500px;
}

.w-768 {
  width: 768px;
}

.mw-768 {
  max-width: 768px;
}

.nonres {
  width: 100%;
  max-width: 1200px;
  min-width: 600px;
  margin: auto;
}

.outline-container {
  border: 1px solid $border-color;
  border-radius: 2px;
  background-color: $sidebar-color;
  color: $darkest;

  .outline-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    min-height: 44px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 1.5rem;
      right: 1.5rem;
      bottom: 0;
      border-bottom: 1px solid $border-color;
    }

    &:last-child {
      &::after {
        border-bottom: 0;
      }
    }
  }
}

.card-info {
  display: flex;
  align-items: center;
}

ul.li-between {
  li {
    display: flex;
    justify-content: space-between;
  }
}

.hours-nav {
  li {
    display: flex;
    justify-content: space-between;

    .day {
      width: 80px;
    }

    .hours {
      width: calc(100% - 100px);
      min-width: 160px;
    }
  }
}

.bg-border {
  background-color: $border-color;
}

.bg-sidebar {
  background-color: $sidebar-color;
}

.bg-alternative {
  background-color: #fafbfc;
}

.nav.order-setup-preview {
  flex-direction: column;

  li {
    padding: 1rem 0;
    display: flex;

    .num {
      width: 25px;
      height: 25px;
      line-height: 1;
      border-radius: 50%;
      color: #fff;
      font-weight: $bold;
      background-color: $green;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
    }

    .text {
      width: calc(100% - 50px);
    }
  }
}

.checkbox-nav {
  flex-direction: column;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid $border-color;

    span {
      font-size: 10px;
      font-weight: $semi-bold;
    }
  }
}

.custom-control-label {
  padding-top: 1px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $success;
  background-color: $success;
}

.code-block {
  height: 100px;
  background-color: $darkest;
  color: #fff;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  letter-spacing: 0.5rem;
}

.location-created-card {
  text-align: center;
  background-color: $sidebar-color;
  padding: 2rem 1.5rem;
  border: 1px solid $border-color;

  .restaurant-name {
    font-size: 16px;
  }

  .location-name {
    font-size: 40px;
    font-weight: $bold;
  }

  .congrate {
    font-size: 30px;
  }
}

.hoverable {
  cursor: pointer;
}

.terms-of-service {
  p,
  li {
    font-size: 20px;
    font-family: "Roboto", Sans-serif;
    color: rgba(7, 32, 62, 0.7019607843137254);
  }
}

.setting-banner-image {
  img {
    height: 100px;
    width: 170px;
    object-fit: cover;
  }

  button {
    width: 170px;
  }
}

.brand-banner-setting {
  img {
    width: 100%;
    height: 125px;
    object-fit: cover;
    margin-top: 17px;
    border: 3px solid white;

    &:hover {
      cursor: pointer;
      border: 3px solid $green;
    }
  }

  .preset {
    img {
      border: 3px solid $green;
    }
  }

  .img-uploader {
    width: 100%;
    height: 125px;
  }
}

.custom-switch {
  ::before {
    cursor: pointer;
  }
}

.order-customer-info {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 1.5rem;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #eaeaea;
  background-color: white;
  z-index: 2;
  position: absolute;
  top: 1px;
  width: 100%;
}

.page-link {
  color: $gray-200;

  &:hover {
    color: white;
    background-color: $green;
    border-color: $green;
  }
}

.page-item {
  .page-link {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    text-align: center;
    margin: 0.25rem;
    text-decoration: none !important;
  }

  &.active {
    .page-link {
      background-color: $green;
      border-color: $green;
    }
  }
}

.pagination-sm {
  padding-top: 30px;
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }

    &:last-child .page-link {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }
}

article.terms {
  max-width: 800px;
  font-size: 14px;
  margin: auto;
  color: $gray-900;
  text-align: justify;

  h1 {
    font-weight: $bold;
    font-size: 2rem;
    text-align: left;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: $semi-bold;
    color: $gray-900;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: left;
  }
}

.badge.notify {
  position: absolute;
  top: 6px;
}
