button:focus {
  outline: none;
}
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}
.btn {
  border-radius: 2px;
  font-size: 14px;
  font-weight: $semi-bold;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}
.btn-lg {
  height: 44px;
}
.btn-sm {
  height: 28px;
  font-size: 12px;
}
a,
.btn-link {
  height: inherit;
  font-weight: $semi-bold;
  color: $green;
  text-decoration: none !important;
  &:hover {
    color: rgba($green, 0.8);
  }
}
.btn-secondary {
  background-color: #fff;
  color: $green;
  font-size: 14px;
  font-weight: $semi-bold;
  border-color: #fff;
  box-shadow: $small-shadow;
  &.btn-sm {
    font-size: 10px;
  }
  &:hover,
  &:focus {
    background-color: $sidebar-color;
    border-color: $sidebar-color;
    color: $green;
  }
}

.btn-outline-secondary {
  border-color: $gray;
  color: $gray;
}

// .btn:not(:disabled):not(.disabled):active,
// .btn:not(:disabled):not(.disabled).active,
// .show > .btn.dropdown-toggle {
//   background-color: inherit !important;
//   border-color: inherit !important;
//   color: inherit !important;
// }
.btn-shadow {
  box-shadow: $small-shadow;
}
.btn-remove {
  i {
    color: $danger;
  }
  color: $darkest;
  font-weight: $regular;
  &:hover {
    color: $darkest;
    box-shadow: $small-shadow;
    border-color: $sidebar-color;
  }
}

.two-btns {
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin: auto;
  & > .btn {
    width: 50%;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.btn-toggle {
  display: flex;
  align-items: center;
  &::after {
    padding-left: 4px;
    content: "\f0c8";
    font-family: "Font Awesome 5 Pro";
    // font-size: 20px;
    font-weight: 500;
  }
  &.checked::after {
    content: "\f14a";
    font-weight: 600;
  }
}

.edit-mode {
  position: relative;
  width: calc(100% - 3rem);
  padding: 0 1.5rem;
  .btn-remove {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.btn-popover {
  display: inline-block;
  color: $gray;
}

.popover {
  border-color: rgba(0, 0, 0, 0.1);
}
.popover-inner {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.1);
}
