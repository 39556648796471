.card-shadow {
  box-shadow: $small-shadow;
  border: 0px;
  background-color: #fff;
}

.form-card {
  max-width: 768px;
  width: calc(100% - 3rem);
  margin: auto;
  padding: 3rem;
  border: 0px;
  box-shadow: $small-shadow;
  color: $darkest;

  .card-body {
    max-width: 100%;
    width: 500px;
    margin: auto;
  }

  h1 {
    font-size: 28px;
    font-weight: $bold;
    text-align: center;
  }
}

@include media-breakpoint-down(md) {
  .form-card {
    padding: 1rem;
    width: calc(100% - 1rem);
  }
}

.feature-card {
  width: calc(33% - 2rem);
  min-width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-width: 0px;
  border-radius: 4px;
  margin: 1rem;
  h4 {
    font-size: 16px;
    font-weight: $bold;
    color: $purple;
    margin-bottom: 1rem;
  }
  p {
    font-size: 12px;
    color: $purple;
  }
  .price {
    font-size: 12px;
    font-weight: $semi-bold;
    color: $purple;
  }
  .action {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #888888;
    font-weight: $semi-bold;
  }
}

.step-card {
  padding: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .fa-check-circle {
    display: none;
  }
  &.checked {
    .label {
      color: $purple;
    }
    .fa-check-circle {
      display: block;
      color: $green;
    }
  }
  &.active {
    .label {
      color: $purple;
    }
  }
}

.feature-setup-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border-width: 0px;
  color: $purple;
}

.login-card {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.5rem;
}