.rc-time-picker-input {
  font-size: 14px;
  color: #222;
  height: 35px;
  border-radius: 2px;
  border-color: #eee;
  padding: 0 0.75rem;
}
.rc-time-picker-clear {
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
