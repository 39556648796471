select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: none;
  padding: 0.375rem 0.75rem;
}

.form-control {
  border-radius: 2px;
  border-color: $border-color;
  font-size: 14px;
  color: $purple;

  &.caret {
    padding-right: 2rem;
    background-image: linear-gradient(45deg, transparent 50%, #222 50%),
      linear-gradient(135deg, #222 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: rgba($green, 0.7);
  }

  &:disabled {
    background-color: $sidebar-color;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $label-color;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $label-color;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $label-color;
  }

  + .form-control {
    margin-top: 0.7rem;
  }
}

.form-group {
  margin-bottom: 2rem;
}

.business-hours-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  label {
    color: $label-color;
    font-weight: $bold;
    font-size: 12px;
    width: 80px;
  }

  .form-control {
    width: 100px;
  }

  .inputs {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      min-width: 40px;
      text-align: center;
    }
  }
}

.content-no-sidebar {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.wrap-control {
  position: relative;

  .wrap-control-body {
    border: 1px solid $border-color;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .content {
    padding: 0.725rem 1.5rem;
  }

  .label {
    color: $gray-500;
    font-size: 12px;
    font-weight: $regular;
  }

  .value {
    color: $purple;
    font-weight: $semi-bold;
    font-size: 14px;
  }

  .control {
    font-size: 16px;
    height: 22px;
    width: 50px;
    border-left: 1px solid $border-color;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 23px;
    color: $gray-300;
  }

  .wrap-control-dd {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: $small-shadow;
    padding: 1.5rem 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.15s, transform 0.25s,
      -webkit-transform 0.25s;

    .dropdown-item {
      padding: 0.5rem 1.5rem;
      color: $darkest;
      font-size: 14px;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translate(0);
    }
  }

  &.input {
    cursor: default;

    .content {
      width: 100%;
    }

    .form-control-plaintext {
      padding-top: 0;
      padding-bottom: 0;
      border-width: 1px;
      font-size: 14px;
    }
  }

  &.select {
    cursor: pointer;

    .label {
      position: absolute;
      left: 1.5rem;
      top: 0.8rem;
      pointer-events: none;
      z-index: 10;
    }

    .select-wrapper {
      position: relative;
      display: block;
      width: 100%;
      padding: 0.5rem 0;

      select {
        display: block;
        height: 52px;
        width: calc(100% - 2px);
        padding: 1.2rem 2.8rem 0rem 1.5rem;
        // color          : $purple;
        // font-size      : 16px;
        font-weight: $semi-bold;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0px;
        border-radius: 2px;
        background-color: #fff;
        transition: all 150ms;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none !important;
      }

      &::before {
        pointer-events: none;
        content: "\f0d7";
        font-family: "Font Awesome 5 Pro";
        font-size: 16px;
        font-weight: 600;
        color: $gray-300;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 1rem;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  input {
    color: $purple;
    font-weight: $semi-bold;

    &:focus {
      outline: none;
    }
  }
}

.dz-error-message {
  color: $red;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: $green;
}

.form-control-plaintext {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $label-color;
    opacity: 1;
    /* Firefox */
    font-weight: $regular;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $label-color;
    font-weight: $regular;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $label-color;
    font-weight: $regular;
  }
}

.modifier-option-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .name {
    width: 220px;
  }

  .price {
    width: 100px;
  }
}

.input-group-text {
  border: 0px;
  border-radius: 2px;
  background-color: #fff;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  font-size: 14px;
}

.input-group > .form-control {
  // border-left: 0px;
  padding-left: 4px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.post-area {
  width: calc(100% - 200px);
}

.table-post-img {
  width: 60px;
  height: 40px;
  background-color: $border-color;
  object-fit: cover;

  &::before {
    content: "\f03e";
    font-family: "Font Awesome 5 Pro";
    font-size: 20px;
    font-weight: 300;
    color: $label-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.stripe_form {
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 14px;
    font-family: $font-family-sans-serif;
    border: 1px solid $border-color;
    outline: 0;
    border-radius: 2px;
    background: white;
    color: $purple !important;
  }

  input::placeholder {
    color: $label-color;
  }

  input:focus,
  .StripeElement--focus {
    border-color: $green;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
}

.text-label {
  color: $label-color;
}

.login-form {
  h1 {
    font-size: 22px;
  }
}

.open-hour-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .from,
  .to {
    width: 100px;
  }

  .btn {
    width: 110px;
  }

  & + .open-hour-row {
    margin-top: 4px;
  }

  .label {
    color: $dark;
    font-size: 14px;
  }
}

.react-tagsinput {
  border-color: $border-color !important;

  &:focus {
    border-color: $green !important;
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}

.online-order-setting {
  .border-ctrl-left {
    border-left: 1px solid $border-color;

    &:focus {
      border-color: $green;
    }
  }

  .border-ctrl-right {
    border-right: 1px solid $border-color;

    &:focus {
      border-color: $green;
    }
  }

  .border-ctrl-select {
    border-color: $border-color;
    font-size: inherit;

    &:focus {
      border-color: $green;
      box-shadow: none;
    }
  }

  .z-index-control .btn {
    z-index: 0;
  }
}

.upload-doc-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba($gray, 0.1);
  border-radius: 4px;
  padding: 0.1rem 1rem;
  color: $gray;
  font-size: 12px;
}
.upload-doc {
  .upload-zone {
    position: relative;
    input[type="file"] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  .preview {
    width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 35px;
  }
}
