@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$bold: 700;
$semi-bold: 600;
$regular: 400;

$body-bg: #fff;
$body-color: #222;
$border-color: #eaeaea;
$label-color: #ccc;
$sidebar-color: #f9f9f9;

$small-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

$gray-200: #aaa;
$gray-300: #888888;
$gray-500: #555555;
$orange: #ff9800;
$yellow: #ffd600;
$dark: #152043;
$darkest: #222;
$cardcolor: #262d45;
$blue: #0a78b4;
$info: #0a78b4;
$red: #ff553e;
$green: #03c9a9;
$gray: #8898aa;
$purple: #32325d;
$warning: #ff9800;
$danger: #ff553e;
$pickup: #0a78b4;
$delivery: #2bbea7;
$curbside: #0a78b4;
