.img-uploader {
  width: 180px;
  position: relative;
  .original-img {
    width: 180px;
    height: 90px;
    background-color: $border-color;
    &::before {
      content: "\f03e";
      font-family: "Font Awesome 5 Pro";
      font-size: 80px;
      font-weight: 300;
      color: $label-color;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  .single-img-preview {
    img {
      width: 100%;
    }
  }

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
  }

  .post-original-img {
    width: 180px;
    height: 120px;
  }
}

.dz-active {
  opacity: 0.5;
  background-color: $sidebar-color;
}
