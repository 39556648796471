.basic-single {
  .select__control {
    border: 0px;
    min-height: 35px;
    border-radius: 2px;
    text-align: center;
  }
  .select__single-value {
    font-size: 14px;
    color: $purple;
  }
  .select__indicator-separator {
    width: 0px !important;
  }
  .select__indicator {
    padding: 6px;
  }
}
